import { Sortable } from 'sortablejs';
import ApplicationController from '../editor/application_controller';
import { createRequest } from '../helpers/createRequest';
import { htmlToElement } from '../helpers/dom';
// import { createRequest } from '../helpers/createRequest';

export default class extends ApplicationController {
  connect() {
    const groups = document.querySelectorAll('.users');
    console.log(groups)
    groups.forEach((group) => {
      Sortable.create(group, {
        animation: 150,
        group: 'users',
        filter: 'input',
        preventOnFilter: true,
        onEnd: (evt) => {
          const groupId  = evt.to.dataset.groupId || '';
          const userId  = evt.item.dataset.userId;
          console.log(groupId, userId)
          const formData = new FormData();
          formData.append('group_id', groupId);
          formData.append('user_id', userId);
          fetch(createRequest(`/x1/studios/${studioId}/groups/members`, 'PATCH', formData))
            .then((response) => response.text())
            .then((html) => {
              console.log(html)
            });
        },
      });
    });
  }

  createGroup(event) {
    console.log(event)
    const { studioId } = event.target.dataset;
    fetch(createRequest(`/x1/studios/${studioId}/groups`, 'POST'))
      .then((response) => response.text())
      .then((html) => {
        const groups = document.querySelector('.groups');
        groups.appendChild(htmlToElement(html))
        this.connect();
      });
  }

  addGroupFormation(event) {
    console.log(event.target.dataset)
    const formData = new FormData();
    formData.append('section_id', event.target.dataset.tabId);
    fetch(createRequest(`/x1/studios/${event.target.dataset.studioId}/groups/formation`, 'PATCH', formData))
      .then((response) => response.text())
      .then((html) => {
        console.log(html)
      });
    window.location.reload();
  }
}
